<template>
  <div class="pageContainer">
    <el-card shadow="never">
      <div slot="header">
        <el-form :inline="true">
          <el-form-item label="类型">
            <el-select v-model="query.type" clearable>
              <el-option value="news" label="咨询"></el-option>
              <el-option value="guide" label="指南"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-row type="flex" justify="start">
        <el-button type="primary" @click="handleClickAdd">新增资讯</el-button>
      </el-row>
      <div class="tableWrap">
        <el-table :data="tableData"
                  border
                  v-loading="loadingList"
                  row-key="id">
          <el-table-column fixed prop="title" label="标题" min-width="130" show-overflow-tooltip>
            <template v-slot="{ row }">
              <router-link :to="{name: 'policyDetail', params: { policyId: row.id }}"><el-button type="text">{{row.title}}</el-button></router-link>
            </template>
          </el-table-column>
          <el-table-column label="类型">
            <template v-slot="{ row }">
              {{row.type | policyType}}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="140"></el-table-column>
          <el-table-column prop="orderNo" label="优先级"></el-table-column>
          <el-table-column label="封面" width="140">
            <template v-slot="{ row }">
              <img :src="row.cover" class="displayImg" alt="">
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            label="操作"
            width="120">
            <template v-slot="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        :current-page.sync="pageObj.currentPage"
        :total="pageObj.total"
        :page-sizes="pageObj.pageSizes"
        :page-size="pageObj.pageSize"
        @current-change = "handlerPageChange"
        @size-change = "handlerSizeChange"
        :layout="pageObj.layout"></el-pagination>
    </el-card>
  </div>
</template>

<script>
  import globalConfig from 'config/global'
  import { execute } from './option'
  import { mapActions } from 'vuex'
  export default {
    name: 'policyList',
    data() {
      return {
        query: {
          type: undefined
        },
        tableData: [],
        loadingList: false,
        pageObj: {
          ...globalConfig.pagination,
          currentPage: 1,
          total: 0
        }
      }
    },
    created () {
      this.getListByPage(this.pageObj.currentPage)
    },
    methods: {
      ...mapActions(['getPolicyList', 'deletePolicy']),
      // 新增
      handleClickAdd() {
        execute('addOrEditPolicy', {
          optType: 'add',
          callback: () => this.getListByPage(1)
        })
      },
      queryData() {
        this.getListByPage(1)
      },
      getListByPage(pageNum) {
        this.loadingList = true
        this.getPolicyList({
          page: pageNum,
          size: this.pageObj.pageSize,
          ...this.query
        }).then(res => {
          const { recordList, total } = res
          this.tableData = recordList || []
          this.pageObj.total = total
        }).finally(() => {
          this.loadingList = false
        })
      },
      handlerPageChange(page) {
        this.getListByPage(page)
      },
      handlerSizeChange(size) {
        this.pageObj.pageSize = size
        this.getListByPage(1)
      },
      handleDelete(row) {
        this.$confirm('确认删除该条资讯吗？').then(res => {
          this.deletePolicy(row.id).then(() => {
            this.$message.success('操作成功')
          }).finally(() => {
            this.getListByPage(this.pageObj.currentPage)
          })
        })
      },
      handleEdit(row) {
        execute('addOrEditPolicy', {
          optType: 'edit',
          detail: { ...row },
          callback: () => this.getListByPage(this.pageObj.currentPage)
        })
      }
    }
  }
</script>

<style scoped>
  .pageContainer {
    padding: 20px;
  }
  .tableWrap {
    margin: 20px 0;
  }
  .displayImg {
    display: block;
    max-width: 120px;
  }
</style>
